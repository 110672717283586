import React, { useContext, useEffect, useState } from "react";
import { Table, Button, Label, Modal,Form, Search,Icon,Select } from "semantic-ui-react";
import { useToasts } from "react-toast-notifications";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import MDL_ADD_CRITICAL_UNIT from "./mdl-add-critical-unit/mdl-add-critical-unit"
import MDL_UPDATE_CRITICAL_UNIT from "./mdl-update-critical-unit/mdl-update-critical-unit";
import {
  CriticalListData,
  getListofScheduler
} from "../../../constants";
import AppPagination from "../../../components/cmp-pagination/Pagination";
import { useHistory } from "react-router-dom";
import "./CriticalList.css";
import moment from "moment";
import MDL_UNIT_HISTORY from "../../scheduler/BuildingHeatmap/Components/mdl_unit_history/mdl_unit_history";
import MDL_VIEW_DETAILS from "./mdl-view-details/mdl-view-details";
import { AppContext } from '../../../Context/AppContext';

function CriticalList() {
   /* VARIABLE DECLARATIONS
   ---------------------------------------------------------------------------------*/
   const { userInfo } = useContext(AppContext);
   const [ user ] = userInfo;
   const [selected_unit, set_selected_unit] = useState(null);
   const [CriticalData, setCriticalData] = useState([]);
   const [SingleCriticalData, setSingleCriticalData] = useState([]);
   const history = useHistory();
   const [page, setPage] = useState(1);
   const [limit, setLimit] = useState(10);
   const [, setTotal] = useState(0);
   const [searchResult, setSearchResult] = useState([]);
   const [searchQuery, setSearchQuery] = useState("");
   const [var_mdl_critical_detail_open,set_mdl_critical_detail_open] = useState(false);
   const [var_mdl_update_critical_detail_open,set_mdl_update_critical_detail_open] = useState(false); 
   const [current_sort, set_current_sorted] = useState({
    sort_by: "building_address",
    sort_by_value: "DESC",
  });
  const [sortBy, setSortBy] = useState(3);
   const { addToast } = useToasts();
   const [var_scheduler_list, set_var_scheduler_list] = useState({
    "value": "all",
    "text": "All",
    "email":"all"
    });;
   const [critical_types, ] = useState([
    {
      text: "Resolved",
      value: "resolved",
    },
    {
      text: "Ongoing",
      value: "ongoing",
    },
  ]);
   const [var_critcal_type,set_critcal_type] =  useState("ongoing");
   const [var_mdl_progress, set_mdl_progress] = useState(false);
   const [schedulers, setSchedulers] = useState();
   const [var_mdl_unit_history, set_mdl_unit_history] = useState(false);
   const [var_refresh, set_refresh] = useState(false);
   const [var_downlaod,set_downlaod]= useState(false)
   const [var_mdl_view_detail_open,set_mdl_view_detail_open]=useState(false);
   const [var_detail_data,set_detail_data] = useState('');
   const [var_detail_title,set_detail_title] = useState('');
   const [building_id,set_building_id] = useState('')
   /* EVENT LISTENERS
  ---------------------------------------------------------------------------------*/
  useEffect(() => {
    get_all_critical_list(1,limit);
  }, [var_mdl_critical_detail_open,var_scheduler_list,var_critcal_type,var_mdl_update_critical_detail_open,var_refresh]);


  useEffect(() => {
    get_all_critical_list(page,limit);
  }, [sortBy,page, limit,current_sort,var_downlaod]);

  useEffect(() => {
    getSchedulerData();
  }, []);

  // useEffect(() => {
  //   if(schedulers){
  //     set_var_scheduler_list({"value":schedulers[0].value,"text":schedulers[0].text,"email":schedulers[0].email})
  //   }
  // }, [schedulers]);




   /* ASYNC FUNCTIONS
  ---------------------------------------------------------------------------------*/
    async function get_all_critical_list(page_no, limit_range, q) {
      var query_params = "";
      if (q) {
        query_params = `?q=${q}&page=${page_no ? page_no : page}&limit=${
          limit_range ? limit_range : limit
        }`;
      } else {
        query_params = `?page=${page_no ? page_no : page}&limit=${
          limit_range ? limit_range : limit
        }`;
      }
      set_mdl_progress(true);
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Token "+user.token },
      }
      const criticalApi = CriticalListData + query_params+`&order_by=${
            current_sort?.sort_by || ""
        }&order_by_value=${current_sort?.sort_by_value || ""}&sort_by=${parseInt(sortBy)}&schedulerID=${var_scheduler_list.value === 'all' ? '' : var_scheduler_list.value}&status=${var_critcal_type}&isExport=${var_downlaod}`;
        if(var_downlaod){
          const response = await fetch(criticalApi, requestOptions);

          if (!response.ok) {
            throw new Error("Failed to fetch the file");
          }
    
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = "critical_list.xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          set_downlaod(false);
            
        }else{
          await fetch(criticalApi,requestOptions).then((response) => response.json())
        .then((res) => {
          var CriticalUnitFetch = [];
          setTotal(res.total_count);
         
          if (res.code === 200) {
            CriticalUnitFetch = res.data;
            if (
              res.page_number > 1 &&
              res &&
              res?.length === 0
            ) {
              setPage(res.page_number - 1);
            }
           
            if(var_refresh){
              addToast("Successfully updated critical list", {
                appearance: 'success',
                autoDismiss: true,
            })
            set_refresh(false);
            }
           
          } else {
            CriticalUnitFetch = [];
          }
          if ((page_no && page_no > 1)){
            CriticalUnitFetch = [...CriticalData, ...CriticalUnitFetch];
          }else{
            setCriticalData([]);
          }
          setCriticalData(CriticalUnitFetch);
          //set_mdl_progress(false);
              
          
        })
        .catch((error) => {
          console.error("Error building get:", error);
        });

        }
        
        set_mdl_progress(false);
    }



    function handleEditCriticalUnit(data){
      setSingleCriticalData(data)
      set_mdl_update_critical_detail_open(true)
    }

   /* EVENT FUNCTIONS
   ---------------------------------------------------------------------------------*/
   async function doSearch(event) {
    const value = event.target.value.toString().toLowerCase();
    setSearchQuery(value);
  }

 function swap(a, b) {
    let  items  = CriticalData;
    items[a] = items.splice(b, 1, items[a])[0];
    items?.forEach((items,index)=>{
      items.priority = index;
    })
    setCriticalData([...items])
  }

  async function getSchedulerData() {
    try {
      const response = await fetch(getListofScheduler);
      const res = await response.json();
      
      // Format the schedulers and add the "All" option
      const formattedSchedulers = [
        { value: 'all', text: 'All' }, // Add the "All" option
        ...res.data.map(scheduler => ({
          value: scheduler.value,
          text: `${scheduler.text} (${scheduler.email})`
        }))
      ];
  
      setSchedulers(formattedSchedulers);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const onChangeScheduler = (e, { value }) => {
    var temp = { ...var_scheduler_list };
    temp.value = value;
    set_var_scheduler_list(temp);
    setPage(1);
  };

  const onChangeCriticalType = (e, { value }) => {
    setPage(1);
    set_critcal_type(value);
  }

  function handleModalUnitHistory(buildingID,unit){
    set_selected_unit(unit);
    set_building_id(buildingID)
    if (unit) {
      openModalUnitHistory();
    } else {
      closeModalUnitHistory();
    }
  }

  function openModalUnitHistory() {
    set_mdl_unit_history(true);
  }

  function closeModalUnitHistory() {
    set_mdl_unit_history(false);
  }

  function truncateText(text, limit) {
    if (text && text?.length > limit) {
      return (
        <>
          {text.substring(0, limit)}...
          <span 
            style={{ color: 'blue', cursor: 'pointer' }} 
            onClick={() => HandleMdlDetails(text, 'Last Tech Comment')}
          >
            Read more
          </span>
        </>
      );
    }
    return text;
  }

  function HandleMdlDetails(data,title){
    set_detail_data(data)
    set_detail_title(title)
    if(data?.length > 0){
      set_mdl_view_detail_open(true)
    }
  }


   /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
     <div className="app-content">
     <div className="app-backto-prev-page">
        <div
          className="app-goback-button"
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon name="arrow left" /> Go Back
        </div>
      </div>
        <div className="app-building-container">
          <div className="app-content-action">
            <div className="app-content-action-right">
              <Button color="red" onClick={() => set_mdl_critical_detail_open(true)}>
                    <img src="/icon-add.svg" alt="icon" />
                    Add Critical Unit
              </Button>
            </div>
          </div>

          <div className="app-card">
            <div className="app-card-header critical-header-card">
              <div className="app-card-title">
                Critical List (
                {searchResult && searchResult?.length > 0
                  ? searchResult?.length
                  : CriticalData?.length}
                )
              </div>
              <div className="critical-filter-container">
                <div className="critical-filter-item refresh-icon">
                <Button color="light" style={{marginRight:"10px"}}  onClick={() =>set_refresh(true)}>
                  <img src="./refresh.svg" alt="icon" />
                  </Button>
                  </div>
                  {schedulers?.length &&
                  <Select
                  placeholder="Select scheduler"
                  fluid
                  id="scheduler_type"
                  value={var_scheduler_list.value}
                  onChange={onChangeScheduler}
                  options={schedulers?.map(option => ({
                    key: option.value,
                    value: option.value,
                    text: (
                      <div>
                        {option.text.split(' ')[0]}{' '}
                        <span style={{ color: 'lightgray' }}>
                          {option.text.split(' ')[1]}
                        </span>
                      </div>
                    )
                  }))}
                />
                }
                <Select
                  name="critical_type"
                  id="critical_type"
                  placeholder="Select type of critical unit"
                  options={critical_types}
                  selectOnBlur={false}
                  value={var_critcal_type}
                  onChange={onChangeCriticalType}
                />
                <div className="critical-filter-item export-icon">
                <Button color="light" style={{marginLeft:"10px",paddingBottom:"9px"}} disabled={CriticalData?.length > 0 ? false : true}  onClick={() =>set_downlaod(true)}>
                  <img src="./download-critical.png" alt="icon" />
                  </Button>
                  </div>
              </div>
            </div>

            <div className="app-table-wrapper">
              <Table sortable unstackable>
              <Table.Header>
                {sortBy != 2 ? (
                  <Table.Row>
                    <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
                    <Table.HeaderCell width={1}
                      sorted={current_sort?.sort_by === "building_address" ? (current_sort.sort_by_value === "ASC" ? "ascending" : "descending") : null}
                      onClick={() => set_current_sorted({ sort_by: "building_address", sort_by_value: current_sort?.sort_by_value === "ASC" ? "DESC" : "ASC" })}
                    >
                      Building
                      {current_sort?.sort_by !== "building_address" && <Icon size="20" name="sort" color="black" />}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    width={1}
                      sorted={current_sort?.sort_by === "customer_name" ? (current_sort.sort_by_value === "ASC" ? "ascending" : "descending") : null}
                      onClick={() => set_current_sorted({ sort_by: "customer_name", sort_by_value: current_sort?.sort_by_value === "ASC" ? "DESC" : "ASC" })}
                    >
                      Client
                      {current_sort?.sort_by !== "customer_name" && <Icon size="20" name="sort" color="black" />}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    width={1}
                      sorted={current_sort?.sort_by === "unit_number" ? (current_sort.sort_by_value === "ASC" ? "ascending" : "descending") : null}
                      onClick={() => set_current_sorted({ sort_by: "unit_number", sort_by_value: current_sort?.sort_by_value === "ASC" ? "DESC" : "ASC" })}
                    >
                      Unit #
                      {current_sort?.sort_by !== "unit_number" && <Icon size="20" name="sort" color="black" />}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    width={1}
                      sorted={current_sort?.sort_by === "date_reported" ? (current_sort.sort_by_value === "ASC" ? "ascending" : "descending") : null}
                      onClick={() => set_current_sorted({ sort_by: "date_reported", sort_by_value: current_sort?.sort_by_value === "ASC" ? "DESC" : "ASC" })}
                    >
                      Date Reported
                      {current_sort?.sort_by !== "date_reported" && <Icon size="20" name="sort" color="black" />}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                    width={1}
                      sorted={current_sort?.sort_by === "tech_reported" ? (current_sort.sort_by_value === "ASC" ? "ascending" : "descending") : null}
                      onClick={() => set_current_sorted({ sort_by: "tech_reported", sort_by_value: current_sort?.sort_by_value === "ASC" ? "DESC" : "ASC" })}
                    >
                      Tech Reported
                      {current_sort?.sort_by !== "tech_reported" && <Icon size="20" name="sort" color="black" />}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: "break-word" }} width={1}>Summary of Issue</Table.HeaderCell> {/* Increased width */}
                    <Table.HeaderCell  style={{ whiteSpace: "normal", wordWrap: "break-word" }} width={2}>Last Scheduled Service Date</Table.HeaderCell>
                    <Table.HeaderCell width={3}>CSR's Actions</Table.HeaderCell> {/* Increased width */}
                    <Table.HeaderCell width={3}>SS/RM Next Step</Table.HeaderCell> {/* Increased width */}
                    <Table.HeaderCell style={{ padding: '0px 100px  10px 0px' }}>Note</Table.HeaderCell> {/* Increased width */}
                    <Table.HeaderCell
                    width={2}
                    style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                      sorted={current_sort?.sort_by === "updated_at" ? (current_sort.sort_by_value === "ASC" ? "ascending" : "descending") : null}
                      onClick={() => set_current_sorted({ sort_by: "updated_at", sort_by_value: current_sort?.sort_by_value === "ASC" ? "DESC" : "ASC" })}
                    >
                      Last Updated Date
                      {current_sort?.sort_by !== "updated_at" && <Icon size="20" name="sort" color="black" />}
                    </Table.HeaderCell>
                  </Table.Row>
                ) : (
                  <Table.Row>
                    <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Building</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Client</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Unit #</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Date Reported</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Tech Reported</Table.HeaderCell>
                    <Table.HeaderCell style={{ whiteSpace: "normal", wordWrap: "break-word" }} width={1}>Summary of Issue</Table.HeaderCell> {/* Increased width */}
                    <Table.HeaderCell  style={{ whiteSpace: "normal", wordWrap: "break-word" }} width={1}>Last Scheduled Service Date</Table.HeaderCell>
                    <Table.HeaderCell width={1}>CSR's Actions</Table.HeaderCell> {/* Increased width */}
                    <Table.HeaderCell width={1}>SS/RM Next Step</Table.HeaderCell> {/* Increased width */}
                    <Table.HeaderCell width={1}>Note</Table.HeaderCell> {/* Increased width */}
                    <Table.HeaderCell  style={{ whiteSpace: "normal", wordWrap: "break-word" }} width={2}>Last Updated Date</Table.HeaderCell>
                  </Table.Row>
                )}
              </Table.Header>

                <Table.Body>
                  {sortBy != 2 && CriticalData && CriticalData?.map((data, i) => (
                    <Table.Row key={i}>
                       <Table.Cell
                        className="td-action"
                        collapsing
                        textAlign="right"
                      >
                          <Label
                          color={data.status === 'ongoing' ? "green" : 'blue'}
                          onClick={()=>handleEditCriticalUnit(data)}
                          >
                             {data.status === 'ongoing' ? 'Edit' : 'View'}
                          </Label>
                      </Table.Cell>
                      <Table.Cell width={1}>{data.building_address+' ('+data.location_number+')'}</Table.Cell>
                      <Table.Cell width={1}>{data.customer_name}</Table.Cell>
                      <Table.Cell width={1} onClick={()=>handleModalUnitHistory(data.buildingID,data.unit_number)} style={{textDecoration: "underline",cursor: "pointer"}}>{data.unit_number}</Table.Cell>
                      <Table.Cell width={1}>{moment(data?.date_reported).format("YYYY-MM-DD")}</Table.Cell>
                      <Table.Cell width={1}>{data.tech_reported}</Table.Cell>
                      <Table.Cell
                       width={3}
                        style={{
                          textDecoration: data.summary?.length > 300 ? "underline" : "none",
                          cursor: data.summary?.length > 300 ? "pointer" : "default"
                        }}
                        onClick={data.summary?.length > 300 ? () => HandleMdlDetails(data.summary, 'Summary of Issue') : null}
                      >
                        {truncateText(data.summary, 300)}
                      </Table.Cell>
                      <Table.Cell width={1}>{moment(data?.last_service_date).format("YYYY-MM-DD")}</Table.Cell>
                      <Table.Cell
                       width={3}
                        style={{
                          textDecoration: data.csr_actions?.length > 300 ? "underline" : "none",
                          cursor: data.csr_actions?.length > 300 ? "pointer" : "default"
                        }}
                        onClick={data.csr_actions?.length > 300 ? () => HandleMdlDetails(data.csr_actions, "CSR's Actions") : null}
                      >
                        {truncateText(data.csr_actions, 300)}
                      </Table.Cell>
                      <Table.Cell
                       width={3}
                        style={{
                          textDecoration: data.ss_rm_steps?.length > 300 ? "underline" : "none",
                          cursor: data.ss_rm_steps?.length > 300 ? "pointer" : "default"
                        }}
                        onClick={data.ss_rm_steps?.length > 300 ? () => HandleMdlDetails(data.ss_rm_steps, 'SS/RM Next Step') : null}
                      >
                        {truncateText(data.ss_rm_steps, 300)}
                      </Table.Cell>
                      <Table.Cell
                       width={4}
                        style={{
                          textDecoration: data.note?.length > 300 ? "underline" : "none",
                          cursor: data.note?.length > 300 ? "pointer" : "default"
                        }}
                        onClick={data.note?.length > 300 ? () => HandleMdlDetails(data.note, 'Note') : null}
                      >
                        {truncateText(data.note, 300)}
                      </Table.Cell>
                      {/* <Table.Cell style={{textDecoration: "underline",cursor: "pointer"}} onClick={()=>HandleMdlDetails(data.last_tech_comment,'Last Tech Comment')}>{truncateText(data.last_tech_comment,30)}</Table.Cell> */}
                      {/* <Table.Cell>{data.status}</Table.Cell> */}
                      <Table.Cell width={2}>{data?.isUnitEdited ? moment(data?.updated_at).format("YYYY-MM-DD") : 'New'}</Table.Cell>
                    </Table.Row>
                  ))}

                {sortBy == 2 && CriticalData && CriticalData?.map((data, i) => (
                    <Table.Row key={i}>
                       <Table.Cell
                        className="td-action"
                        collapsing
                        textAlign="right"
                      >
                          <Label
                          color= {data.status === 'ongoing' ? "green" : 'blue'}
                          onClick={()=>handleEditCriticalUnit(data)}
                          >
                             {data.status === 'ongoing' ? 'Edit' : 'View'}
                          </Label>
                      </Table.Cell>
                      <Table.Cell width={1}>{data.building_address+'('+data.location_number+')'}</Table.Cell>
                      <Table.Cell width={1}>{data.customer_name}</Table.Cell>
                      <Table.Cell width={1} onClick={()=>handleModalUnitHistory(data.unit_number)} style={{textDecoration: "underline",cursor: "pointer"}}>{data.unit_number}</Table.Cell>
                      <Table.Cell width={1}>{moment(data?.date_reported).format("YYYY-MM-DD")}</Table.Cell>
                      <Table.Cell width={1}>{data.tech_reported}</Table.Cell>
                      <Table.Cell
                       width={3}
                        style={{
                          textDecoration: data.summary?.length > 300 ? "underline" : "none",
                          cursor: data.summary?.length > 300 ? "pointer" : "default"
                        }}
                        onClick={data.summary?.length > 300 ? () => HandleMdlDetails(data.summary, 'Summary of Issue') : null}
                      >
                        {truncateText(data.summary, 300)}
                      </Table.Cell>
                      <Table.Cell width={1}>{moment(data?.last_service_date).format("YYYY-MM-DD")}</Table.Cell>
                      <Table.Cell
                       width={3}
                        style={{
                          textDecoration: data.csr_actions?.length > 300 ? "underline" : "none",
                          cursor: data.csr_actions?.length > 300 ? "pointer" : "default"
                        }}
                        onClick={data.csr_actions?.length > 300 ? () => HandleMdlDetails(data.csr_actions, "CSR's Actions") : null}
                      >
                        {truncateText(data.csr_actions, 300)}
                      </Table.Cell>
                      <Table.Cell
                       width={3}
                        style={{
                          textDecoration: data.ss_rm_steps?.length > 300 ? "underline" : "none",
                          cursor: data.ss_rm_steps?.length > 300 ? "pointer" : "default"
                        }}
                        onClick={data.ss_rm_steps?.length > 300 ? () => HandleMdlDetails(data.ss_rm_steps, 'SS/RM Next Step') : null}
                      >
                        {truncateText(data.ss_rm_steps, 300)}
                      </Table.Cell>
                      <Table.Cell
                       width={4}
                        style={{
                          textDecoration: data.note?.length > 300 ? "underline" : "none",
                          cursor: data.note?.length > 300 ? "pointer" : "default"
                        }}
                        onClick={data.note?.length > 300 ? () => HandleMdlDetails(data.note, 'Note') : null}
                      >
                        {truncateText(data.note, 300)}
                      </Table.Cell>
                      {/* <Table.Cell  style={{textDecoration: "underline",cursor: "pointer"}} onClick={()=>HandleMdlDetails(data.last_tech_comment,'Last Tech Comment')}>{truncateText(data.last_tech_comment,30)}</Table.Cell> */}
                      {/* <Table.Cell>{data.status}</Table.Cell> */}
                      <Table.Cell width={2}>{data?.isUnitEdited ? moment(data?.updated_at).format("YYYY-MM-DD") : 'New'}</Table.Cell>
                    </Table.Row>
                  ))}
                 
                </Table.Body>
              </Table>
              {CriticalData?.length === 0 && (
                <div className="nodata-placeholder" style={{paddingBottom:'10px'}}>
                  There is no Critical list to display
                </div>
              )}
            </div>
          </div>
          <AppPagination
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            rowsTotal={CriticalData?.length}
          />
        </div>
      </div>
        {/***** MODAL: PROGRESS **************************************************************************/}
          <Modal
            id="mdl-progress"
            dimmer={"inverted"}
            open={var_mdl_progress}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            onClose={() => {}}
          >
            <MDL_PROGRESS
              set_mdl_open={set_mdl_progress}
              var_modaltitle="Please wait"
              var_message={"Stay on this page until this process finish."}
            ></MDL_PROGRESS>
          </Modal>

        {/***** END MODAL: PROGRESS **************************************************************************/}
        {/***** MODAL: ADD Critical Detail **************************************************************************/}
         <Modal
        id="mdl-critical-detail"
		    style={{overflow:'visible'}}
        dimmer={"inverted"}
        open={var_mdl_critical_detail_open}
        onClose={() => {}}
      >
        <MDL_ADD_CRITICAL_UNIT
          set_mdl_open={set_mdl_critical_detail_open}
          setPage={setPage}
        ></MDL_ADD_CRITICAL_UNIT>
      </Modal>
       {/***** END MODAL: ADD Critical Detail **************************************************************************/}
       {/***** MODAL: UPDATE Critical Detail **************************************************************************/}
       <Modal
        id="mdl-critical-detail"
		    style={{overflow:'visible'}}
        dimmer={"inverted"}
        open={var_mdl_update_critical_detail_open}
        onClose={() => {}}
      >
        <MDL_UPDATE_CRITICAL_UNIT
          set_mdl_open={set_mdl_update_critical_detail_open}
          SingleCriticalData={SingleCriticalData}
          setPage={setPage}
        ></MDL_UPDATE_CRITICAL_UNIT>
      </Modal>
      {/***** END MODAL: UPDATE Critical Detail **************************************************************************/}
      {/***** MODAL: UNITS HISTORY **************************************************************************/}
      <Modal
          id="mdl-unit-history"
          dimmer={"inverted"}
          size="large"
          open={var_mdl_unit_history}
          onClose={() => {
            set_mdl_unit_history(false)
            set_selected_unit(false)}}
        >
          <MDL_UNIT_HISTORY
            unit_number={selected_unit}
            set_mdl_open={set_mdl_unit_history}
            building_id={building_id}
            set_selected_unit={set_selected_unit}
          />
        </Modal>
        
        {/***** MODAL: UNITS HISTORY **************************************************************************/}
         {/***** MODAL: UNITS HISTORY **************************************************************************/}
      <Modal
          id="mdl-view-detail"
          dimmer={"inverted"}
          size="large"
          open={var_mdl_view_detail_open}
          onClose={() => {
            set_mdl_view_detail_open(false)}}
        >
          <MDL_VIEW_DETAILS
            set_mdl_open={set_mdl_view_detail_open}
            data={var_detail_data}
            title={var_detail_title}
          />
        </Modal>
        {/***** MODAL: UNITS HISTORY **************************************************************************/}
    </>
  )
}

export default CriticalList;
