import React, { useContext, useEffect, useState } from "react";
import { Table, Button, Label, Modal,Form, Search,Icon,Select } from "semantic-ui-react";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { AppContext } from '../../../../Context/AppContext';
import {
  getBuildingNotes,
  deleteBuildingNotes
} from "../../../../constants";
import MDL_PROGRESS from "../../../../components/cmp-progress/cmp-progress";
import { useHistory,useParams } from "react-router-dom";
import "../order-request-form/cmp-add-unit/cmp-add-unit.css";
import AppPagination from "../../../../components/cmp-pagination/Pagination";
import MDL_ADD_NOTE from "./mdl-add-note/mdl-add-note"
import MDL_UPDATE_NOTE from "./mdl-update-note/mdl-update-note";

const NOTES = ({ index }) => {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const { userInfo } = useContext(AppContext);
   const [ user ] = userInfo;
   const params = useParams();
  const [NotesData, setNotesData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [NoteData, setNoteData] = useState([]);
  const [var_mdl_note_open,set_mdl_note_open] = useState(false);
  const history = useHistory();
  const [current_sort, set_current_sorted] = useState({
    sort_by: "created_at",
    sort_by_value: "DESC",
  });
  const [sortBy, setSortBy] = useState(3);
   const { addToast } = useToasts();
   const [page, setPage] = useState(1);
   const [limit, setLimit] = useState(10);
   const [, setTotal] = useState(0);
   const [var_mdl_progress, set_mdl_progress] = useState(false);
   const [var_mdl_update_note_open,set_mdl_update_note_open] = useState(false); 

  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    get_all_note_list(1,limit);
  }, [var_mdl_note_open,var_mdl_update_note_open]);

  useEffect(() => {
    get_all_note_list(page,limit);
  }, [sortBy,page, limit,current_sort]);


  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  async function get_all_note_list(page_no, limit_range, q) {
    var query_params = "";
      if (q) {
        query_params = `?q=${q}&page=${page_no ? page_no : page}&limit=${
          limit_range ? limit_range : limit
        }`;
      } else {
        query_params = `?page=${page_no ? page_no : page}&limit=${
          limit_range ? limit_range : limit
        }`;
      }

      set_mdl_progress(true);
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Token "+user.token },
      }
      const noteApi = getBuildingNotes + query_params+`&buildingID=${params?.building_id}`+`&order_by=${
            current_sort?.sort_by || ""
        }&order_by_value=${current_sort?.sort_by_value || ""}&sort_by=${parseInt(sortBy)}`;
       
        await fetch(noteApi,requestOptions).then((response) => response.json())
        .then((res) => {
          var NotesFetch = [];
          setTotal(res.total_count);
         
          if (res.code === 200) {
            NotesFetch = res.data;
            if (
              res.page_number > 1 &&
              res &&
              res &&
              res?.length === 0
            ) {
              setPage(res.page_number - 1);
            }
           
          } else {
            NotesFetch = [];
          }
          if ((page_no && page_no > 1)){
            NotesFetch = [...NotesData, ...NotesFetch];
          }else{
            setNotesData([]);
          }
          setNotesData(NotesFetch);
          //set_mdl_progress(false);
              
          
        })
        .catch((error) => {
          console.error("Error building get:", error);
        });

        
        set_mdl_progress(false);
  }


  async function handleEditNote(data) {
    setNoteData(data);
    set_mdl_update_note_open(true);
  }

  async function handleDeleteNote(data) {
    set_mdl_progress(true);
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': "Token "+user.token },
      };
      var apiUrl=deleteBuildingNotes+`${data?.uuid}`;
      await fetch(apiUrl,requestOptions)
      .then(response=>response.json())
      .then(res=>{
          const{success,code}=res;
          if(code===200){
              addToast(success, {
                  appearance: 'success',
                  autoDismiss: true,
              })
              get_all_note_list(1,limit);
          }
          else{
              addToast(success, {
                  appearance: 'error',
                  autoDismiss: true,
              })
          }
          
      })
      .catch(error=>{
          console.error("Error:",error);
      })
      set_mdl_progress(false);
  }




  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
    <>
     <div className="app-content">
     <div className="app-backto-prev-page">
        <div
          className="app-goback-button"
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon name="arrow left" /> Go Back
        </div>
      </div>
        <div className="app-building-container">
          <div className="app-content-action">
            <div className="app-content-action-right">
              <Button color="red" onClick={() => set_mdl_note_open(true)}>
                    <img src="/icon-add.svg" alt="icon" />
                    Add Note
              </Button>
            </div>
          </div>

          <div className="app-card">
            <div className="app-card-header critical-header-card">
              <div className="app-card-title">
                Notes List (
                {searchResult && searchResult?.length > 0
                  ? searchResult?.length
                  : NotesData?.length}
                )
              </div>
            </div>

            <div className="app-table-wrapper">
              <Table sortable unstackable>
              <Table.Header>
                {sortBy != 2 ? (
                  <Table.Row>
                    
                    <Table.HeaderCell
                    width={1}
                      sorted={current_sort?.sort_by === "created_at" ? (current_sort.sort_by_value === "ASC" ? "ascending" : "descending") : null}
                      onClick={() => set_current_sorted({ sort_by: "created_at", sort_by_value: current_sort?.sort_by_value === "ASC" ? "DESC" : "ASC" })}
                    >
                      Date
                      {current_sort?.sort_by !== "created_at" && <Icon size="20" name="sort" color="black" />}
                    </Table.HeaderCell>
                    <Table.HeaderCell>Note</Table.HeaderCell> {/* Increased width */}
                    <Table.HeaderCell width={2}>
                      Type
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
                  </Table.Row>
                ) : (
                  <Table.Row>
                    <Table.HeaderCell width={1}>Date</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Note</Table.HeaderCell> {/* Increased width */}
                    <Table.HeaderCell width={2}>Type</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
                  </Table.Row>
                )}
              </Table.Header>

                <Table.Body>
                  {sortBy != 2 && NotesData && NotesData?.map((data, i) => (
                    <Table.Row key={i}>
                      
                      <Table.Cell width={1}>{moment(data?.created_at).format("YYYY-MM-DD")}</Table.Cell>
                      <Table.Cell
                       width={12}
                      >
                        {data.notes}
                      </Table.Cell>
                      <Table.Cell width={2}>{data.note_type}</Table.Cell> 
                      <Table.Cell
                        className="td-action"
                        collapsing
                        textAlign="right"
                      >
                          <Label
                          color={'blue'}
                          onClick={()=>handleEditNote(data)}
                          >
                             {'Edit' }
                          </Label>
                          <Label
                          color={'red'}
                          onClick={()=>handleDeleteNote(data)}
                          >
                             {'Delete'}
                          </Label>
                      </Table.Cell>
                    </Table.Row>
                  ))}

                {sortBy == 2 && NotesData && NotesData?.map((data, i) => (
                    <Table.Row key={i}>
                       <Table.Cell width={2}>{moment(data?.created_at).format("YYYY-MM-DD")}</Table.Cell>
                       <Table.Cell
                       width={12}
                      >
                        {data.notes}
                      </Table.Cell>
                      <Table.Cell width={2}>{data.note_type}</Table.Cell> 
                      <Table.Cell
                        className="td-action"
                        collapsing
                        textAlign="right"
                      >
                          <Label
                          color={'blue'}
                          onClick={()=>handleEditNote(data)}
                          >
                             {'Edit' }
                          </Label>
                          <Label
                          color={'red'}
                          onClick={()=>handleDeleteNote(data)}
                          >
                             {'Delete' }
                          </Label>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                 
                </Table.Body>
              </Table>
              {NotesData?.length === 0 && (
                <div className="nodata-placeholder" style={{paddingBottom:'10px'}}>
                  There is no Notes list to display
                </div>
              )}
            </div>
          </div>
          <AppPagination
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}
            rowsTotal={NotesData?.length}
          />
        </div>
      </div>
        {/***** MODAL: PROGRESS **************************************************************************/}
          <Modal
            id="mdl-progress"
            dimmer={"inverted"}
            open={var_mdl_progress}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            onClose={() => {}}
          >
            <MDL_PROGRESS
              set_mdl_open={set_mdl_progress}
              var_modaltitle="Please wait"
              var_message={"Stay on this page until this process finish."}
            ></MDL_PROGRESS>
          </Modal>

        {/***** END MODAL: PROGRESS **************************************************************************/}
        {/***** MODAL: ADD Notes Detail **************************************************************************/}
        <Modal
        id="mdl-critical-detail"
		    style={{overflow:'visible'}}
        dimmer={"inverted"}
        open={var_mdl_note_open}
        onClose={() => {}}
      >
        <MDL_ADD_NOTE
          set_mdl_open={set_mdl_note_open}
          buildingID={params?.building_id}
          setPage={setPage}
        ></MDL_ADD_NOTE>
      </Modal>
       {/***** END MODAL: ADD Notes Detail **************************************************************************/}
       {/***** MODAL: UPDATE Notes Detail **************************************************************************/}
       <Modal
        id="mdl-critical-detail"
		    style={{overflow:'visible'}}
        dimmer={"inverted"}
        open={var_mdl_update_note_open}
        onClose={() => {}}
      >
        <MDL_UPDATE_NOTE
          set_mdl_open={set_mdl_update_note_open}
          NoteData={NoteData}
          setPage={setPage}
        ></MDL_UPDATE_NOTE>
      </Modal>
      {/***** END MODAL: UPDATE Notes Detail **************************************************************************/}
      

    </>
      
    </>
  );
};
export default NOTES;
